<template>
  <div class="category__top">
    <div class="category__header">
      <h3 class="category__name">Fresh</h3>
      <div class="category__search">
        <form action="#">
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              placeholder="Search in Fresh..."
            />
            <div class="input-group-append"><i class="icon-magnifier"></i></div>
          </div>
        </form>
      </div>
    </div>
    <p class="category__des">
      <b class="text-black">Fresh food</b> is food which has not been preserved
      and has not spoiled yet. For vegetables and fruits, this means that they
      have been recently harvested and treated properly postharvest; for meat,
      it has recently been slaughtered and butchered; for fish, it has been
      recently caught or harvested and kept cold.
    </p>
    <div class="shop__block category__carousel">
      <div
        class="owl-carousel"
        data-owl-auto="true"
        data-owl-loop="true"
        data-owl-speed="5000"
        data-owl-gap="0"
        data-owl-nav="true"
        data-owl-dots="true"
        data-owl-item="5"
        data-owl-item-xs="2"
        data-owl-item-sm="2"
        data-owl-item-md="3"
        data-owl-item-lg="5"
        data-owl-item-xl="5"
        data-owl-duration="1000"
        data-owl-mousedrag="on"
      >
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_35a.jpg" alt />
          <div class="categogy-name">Meat &amp; Poultry</div>
          <div class="categogy-number">16 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_4a.jpg" alt />
          <div class="categogy-name">Fruit</div>
          <div class="categogy-number">23 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_16a.jpg" alt />
          <div class="categogy-name">Vegetables</div>
          <div class="categogy-number">16 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_20a.jpg" alt />
          <div class="categogy-name">Milks, Butter &amp; Eggs</div>
          <div class="categogy-number">15 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_37a.jpg" alt />
          <div class="categogy-name">Fish</div>
          <div class="categogy-number">4 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_6a.jpg" alt />
          <div class="categogy-name">Frozen</div>
          <div class="categogy-number">45 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_28a.jpg" alt />
          <div class="categogy-name">Cheese</div>
          <div class="categogy-number">23 items</div>
        </div>
        <div class="categogy-item">
          <img src="img/products/01-Fresh/01_7a.jpg" alt />
          <div class="categogy-name">Pasta &amp; Sauce</div>
          <div class="categogy-number">10 items</div>
        </div>
      </div>
    </div>
  </div>
</template>