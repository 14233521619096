<template>
  <main class="no-main">
    <div class="ps-breadcrumb">
      <div class="container-fluid-sy">
        <ul class="ps-breadcrumb__list">
          <li class="active"><a href="#">Home</a></li>
          <li><a href="javascript:void(0);">Fresh</a></li>
        </ul>
      </div>
    </div>
    <section class="section-shop shop-categories--default">
      <div class="container">
        <div class="row">
          <!-- CategoriesSideMenu Section -->
          <CategoriesSideMenu />

          <div class="col-12 col-lg-9">
            <!-- CategoriesTop Section -->
            <CategoriesTop />

            <!-- Products Section -->
            <Products />
          </div>
        </div>
      </div>
    </section>
    <!-- RecentView Section -->
    <RecentView />

    <!-- QuickViewModal Section -->
    <QuickViewModal />

    <!-- AddToCartModal Section -->
    <AddToCartModal />
  </main>
</template>
<script>
import CategoriesSideMenu from "./Components/CategoriesSideMenu";
import CategoriesTop from "./Components/CategoriesTop";
import Products from "./Components/Products";
import RecentView from "./Components/RecentView";
import QuickViewModal from "./Components/QuickViewModal";
import AddToCartModal from "./Components/AddToCartModal";

export default {
  components: {
    CategoriesSideMenu,
    CategoriesTop,
    Products,
    RecentView,
    QuickViewModal,
    AddToCartModal,
  },
};
</script>
