<template>
  <div class="col-12 col-lg-3">
    <div class="ps-shop--sidebar">
      <div class="sidebar__category">
        <div class="sidebar__title">ALL CATEGORIES</div>
        <div class="ps-widget--category">
          <h4>Fresh</h4>
          <ul>
            <li><a href="#">Meat & Poultry</a></li>
            <li><a href="#">Fruit</a></li>
            <li><a href="#">Vegetables</a></li>
            <li><a href="#">Milks, Butter & Eggs</a></li>
            <li><a href="#">Fish</a></li>
            <li><a href="#">Frozen</a></li>
            <li><a href="#">Cheese</a></li>
            <li><a href="#">Pasta & Sauce</a></li>
          </ul>
        </div>
      </div>
      <div class="sidebar__sort">
        <div class="sidebar__block open">
          <div class="sidebar__title">
            BY BRANDS<span class="shop-widget-toggle"
              ><i class="icon-minus"></i
            ></span>
          </div>
          <form action="#">
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="Search brand..."
              />
              <div class="input-group-append">
                <i class="icon-magnifier"></i>
              </div>
            </div>
          </form>
          <div class="brand__content">
            <ul>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox0"
                    value=""
                  />
                  <label for="checkbox0"
                    ><img src="img/brand/brand_themeforest.jpg" alt /><span
                      >(39)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox1"
                    value=""
                  />
                  <label for="checkbox1"
                    ><img src="img/brand/brand_envato.jpg" alt /><span
                      >(146)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox2"
                    value=""
                  />
                  <label for="checkbox2"
                    ><img src="img/brand/brand_codecanyon.jpg" alt /><span
                      >(20)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox3"
                    value=""
                  />
                  <label for="checkbox3"
                    ><img src="img/brand/brand_cudicjungle.jpg" alt /><span
                      >(16)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox4"
                    value=""
                  />
                  <label for="checkbox4"
                    ><img src="img/brand/brand_videohive.jpg" alt /><span
                      >(50)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox5"
                    value=""
                  />
                  <label for="checkbox5"
                    ><img src="img/brand/brand_photodune.jpg" alt /><span
                      >(23)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox6"
                    value=""
                  />
                  <label for="checkbox6"
                    ><img src="img/brand/brand_evatotuts.jpg" alt /><span
                      >(11)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox7"
                    value=""
                  />
                  <label for="checkbox7"
                    ><img src="img/brand/brand_3docean.jpg" alt /><span
                      >(67)</span
                    ></label
                  >
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkbox8"
                    value=""
                  />
                  <label for="checkbox8"
                    ><img src="img/brand/microlancer.jpg" alt /><span
                      >(34)</span
                    ></label
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="sidebar__block open">
          <div class="sidebar__title">
            BY PRICE<span class="shop-widget-toggle"
              ><i class="icon-minus"></i
            ></span>
          </div>
          <div class="block__content">
            <div class="block__price">
              <div id="slide-price"></div>
            </div>
            <div class="block__input">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  class="form-control"
                  type="text"
                  id="input-with-keypress-0"
                />
              </div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  class="form-control"
                  type="text"
                  id="input-with-keypress-1"
                />
              </div>
              <button>Go</button>
            </div>
          </div>
        </div>
        <div class="sidebar__block open">
          <div class="sidebar__title">
            AVG. REVIEW<span class="shop-widget-toggle"
              ><i class="icon-minus"></i
            ></span>
          </div>
          <div class="block__content">
            <ul>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="avg5"
                    value=""
                  />
                  <label for="rating5">
                    <select class="rating-stars" id="rating5">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5" selected="selected">5</option></select
                    ><span class="text-up">& Up</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="avg4"
                    value=""
                  />
                  <label for="rating4">
                    <select class="rating-stars" id="rating4">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4" selected="selected">4</option>
                      <option value="5">5</option></select
                    ><span class="text-up">& Up</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="avg3"
                    value=""
                  />
                  <label for="rating3">
                    <select class="rating-stars" id="rating3">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3" selected="selected">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option></select
                    ><span class="text-up">& Up</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="avg2"
                    value=""
                  />
                  <label for="rating2">
                    <select class="rating-stars" id="rating2">
                      <option value="1">1</option>
                      <option value="2" selected="selected">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option></select
                    ><span class="text-up">& Up</span>
                  </label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="avg1"
                    value=""
                  />
                  <label for="rating1">
                    <select class="rating-stars" id="rating1">
                      <option value="1" selected="selected">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option></select
                    ><span class="text-up">& Up</span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="sidebar__block open">
          <div class="sidebar__title">
            SOLD BY<span class="shop-widget-toggle"
              ><i class="icon-minus"></i
            ></span>
          </div>
          <div class="block__content">
            <ul>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="sold0"
                    value=""
                  />
                  <label for="sold0">Farmart<span>(55)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="sold1"
                    value=""
                  />
                  <label for="sold1">All Vendors<span>(223)</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="sidebar__block open">
          <div class="sidebar__title">
            OPTIONS<span class="shop-widget-toggle"
              ><i class="icon-minus"></i
            ></span>
          </div>
          <div class="block__content">
            <ul>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="option0"
                    value=""
                  />
                  <label for="option0">On Sales<span>(145)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="option1"
                    value=""
                  />
                  <label for="option1">new<span>(43)</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="sidebar__block open">
          <div class="sidebar__title">
            DIETARY & LIFESTYLE<span class="shop-widget-toggle"
              ><i class="icon-minus"></i
            ></span>
          </div>
          <div class="block__content">
            <ul>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="life0"
                    value=""
                  />
                  <label for="life0">Vegetarian<span>(23)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="life1"
                    value=""
                  />
                  <label for="life1">Vegan<span>(37)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="life2"
                    value=""
                  />
                  <label for="life2">Gluten Free<span>(114)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="life3"
                    value=""
                  />
                  <label for="life3">Milk Free<span>(50)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="life4"
                    value=""
                  />
                  <label for="life4">Fat Free<span>(24)</span></label>
                </div>
              </li>
              <li>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="life5"
                    value=""
                  />
                  <label for="life5">Organic<span>(18)</span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>