<template>
  <div class="result__header">
    <h4 class="title">35<span>Product Found</span></h4>
    <div class="page">page <input type="text" value="1" disabled />of 3</div>
  </div>
  <div class="result__sort mt-5">
    <div class="viewtype--block">
      <div class="viewtype__sortby">
        <div class="select">
          <select class="single-select2-no-search" name="state">
            <option value="popularity" selected="selected">
              Sort by popularity
            </option>
            <option value="price">Sort by price</option>
            <option value="sale">Sort by sale of</option>
          </select>
        </div>
      </div>
      <div class="viewtype__select">
        <span class="text">View: </span>
        <div class="select">
          <select class="single-select2-no-search" name="state">
            <option value="25" selected="selected">25 per page</option>
            <option value="12">12 per page</option>
            <option value="5">5 per page</option>
          </select>
        </div>
        <div class="type">
          <router-link :to="{ name: 'shop-categories' }"
            ><span class="active"><i class="icon-icons"></i></span
          ></router-link>
          <a href="#"
            ><span><i class="icon-grid3"></i></span
          ></a>
          <router-link :to="{ name: 'products' }"
            ><span><i class="icon-list4"></i></span
          ></router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="result__header mobile">
    <h4 class="title">35<span>Product Found</span></h4>
  </div>
  <div class="result__content mt-4">
    <div class="section-shop--grid">
      <div class="row m-0">
        <div
          class="col-6 col-md-4 col-lg-3 p-0"
          v-for="products in 12"
          :key="products"
        >
          <div class="ps-product--standard">
            <a href="product-default.html"
              ><img
                class="ps-product__thumbnail"
                src="img/products/01-Fresh/01_17a.jpg"
                alt="alt" /></a
            ><a
              class="ps-product__expand"
              href="javascript:void(0);"
              data-toggle="modal"
              data-target="#popupQuickview"
              ><i class="icon-expand"></i></a
            ><span class="ps-badge ps-product__offbadge">32% Off </span>
            <div class="ps-product__content">
              <p class="ps-product__type"><i class="icon-store"></i>Farmart</p>
              <h5>
                <a class="ps-product__name" href="product-default.html"
                  >Tomato Nature Sweet Glor</a
                >
              </h5>
              <p class="ps-product__unit">300g</p>
              <div class="ps-product__rating">
                <select class="rating-stars">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5" selected="selected">5</option></select
                ><span>(14)</span>
              </div>
              <p class="ps-product-price-block">
                <span class="ps-product__sale">$20.00</span
                ><span class="ps-product__price">$12.00</span>
              </p>
            </div>
            <div class="ps-product__footer">
              <div class="def-number-input number-input safari_only">
                <button
                  class="minus"
                  onclick="this.parentNode.querySelector('input[type=number]').stepDown()"
                >
                  <i class="icon-minus"></i>
                </button>
                <input
                  class="quantity"
                  min="0"
                  name="quantity"
                  value="1"
                  type="number"
                />
                <button
                  class="plus"
                  onclick="this.parentNode.querySelector('input[type=number]').stepUp()"
                >
                  <i class="icon-plus"></i>
                </button>
              </div>
              <div class="ps-product__total">Total: <span>$20.00</span></div>
              <button
                class="ps-product__addcart"
                data-toggle="modal"
                data-target="#popupAddToCart"
              >
                <i class="icon-cart"></i>Add to cart
              </button>
              <div class="ps-product__box">
                <a class="ps-product__wishlist" href="wishlist.html">Wishlist</a
                ><a class="ps-product__compare" href="wishlist.html">Compare</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ps-pagination blog--pagination">
      <ul class="pagination">
        <li class="chevron">
          <a href="#"><i class="icon-chevron-left"></i></a>
        </li>
        <li class="active"><a href="#">1</a></li>
        <li><a href="#">2</a></li>
        <li><a href="#">3</a></li>
        <li class="chevron">
          <a href="#"><i class="icon-chevron-right"></i></a>
        </li>
      </ul>
    </div>
  </div>
</template>