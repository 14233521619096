<template>
  <section class="section-recent--default ps-home--block">
    <div class="container">
      <div class="ps-block__header">
        <h3 class="ps-block__title">Your Recent Viewed</h3>
        <a class="ps-block__view" href="shop-categories.html"
          >View all <i class="icon-chevron-right"></i
        ></a>
      </div>
      <div class="recent__content">
        <div
          class="owl-carousel"
          data-owl-auto="true"
          data-owl-loop="true"
          data-owl-speed="5000"
          data-owl-gap="0"
          data-owl-nav="true"
          data-owl-dots="true"
          data-owl-item="8"
          data-owl-item-xs="3"
          data-owl-item-sm="3"
          data-owl-item-md="5"
          data-owl-item-lg="8"
          data-owl-item-xl="8"
          data-owl-duration="1000"
          data-owl-mousedrag="on"
        >
          <a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_1a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_2a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_30a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_10a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_18a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_28b.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_16a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_31a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_15a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_5a.jpg" alt="alt" /></a
          ><a class="recent-item" href="index.html"
            ><img src="img/products/01-Fresh/01_32a.jpg" alt="alt"
          /></a>
        </div>
      </div>
    </div>
  </section>
</template>